<template>
  <section class="catalog-products flex flex-col justify-center">
    <TitleItem text="Каталог товаров"></TitleItem>
    <div v-if="isMobile" class="flex flex-row justify-between">
      <button
        @click="showSortingMenu"
        class="sort-mobile rounded-xl flex flex-row justify-center items-center gap-1.5 mb-3"
      >
        <img src="@/assets/images/sorting.png" width="18" height="18" alt="sort">
        <span class="text-lg">Сортировка</span>
      </button>
      <button
        @click="showFiltersMenu"
        class="sort-mobile rounded-xl flex flex-row justify-center items-center gap-1.5 mb-3"
      >
        <img src="@/assets/images/filter.png" width="18" height="18" alt="filter">
        <span class="text-lg">Фильтры</span>
      </button>
    </div>
    <div v-else class="sort flex flex-row justify-start items-center mb-6 gap-[25px]">
      <button
        v-for="sorting of sortings"
        :key="sorting"
        @click="getProducts(sorting.name)"
        :class="{ active: sortedBy === sorting.name }"
      >{{ sorting.title }}</button>
    </div>
    <div class="flex flex-row justify-between gap-6">
      <div v-if="noProducts">
        <h1>Не удалось найти товары по вашему запросу</h1>
      </div>
      <div v-else-if="!isProductsLoading" class="w-full flex flex-col justify-start items-center gap-[25px]">
        <div v-for="row in productsRows" :key="row" class="row w-full flex flex-row justify-start gap-[2.67%]">
          <ProductCardCatalog
            v-for="product in row"
            :key="product"
            :product="product"
          ></ProductCardCatalog>
        </div>
      </div>
      <LoadingMark v-else></LoadingMark>
      <aside v-if="!isMobile" class="filters flex flex-col justify-start">
        <form @submit.prevent>
          <div class="filters__categories mb-6">
            <div class="flex flex-row justify-between items-center mb-3">
              <h3 class="text-[28px] font-bold">Категории</h3>
              <ClearButton @clear="clearCategories"></ClearButton>
            </div>
            <div class="flex flex-col items-start gap-3">
              <CategoryCheckbox
                v-for="category in categories"
                :key="category"
                :categoryID="category.id"
                :categoryName="category.name"
                :subcategories="category.subcategories"
                @setCheck="toggleCategory"
                @setUnCheck="toggleCategory"
              ></CategoryCheckbox>
            </div>
          </div>
          <div class="filters__price">
            <div class="flex flex-row justify-between items-center mb-3">
              <h3 class="text-[28px] font-bold">Фильтры</h3>
              <ClearButton @clear="clearPrices"></ClearButton>
            </div>
            <h4 class="text-[24px] font-bold text-left">Цена</h4>
            <div class="price-inputs flex flex-row gap-2">
              <input
                class="rounded-lg text-center"
                type="number"
                min="0"
                placeholder="От"
                v-model="minPrice"
                @change="updateInput"
                @keydown.enter="$event.target.blur()"
              >
              <input
                class="rounded-lg text-center"
                type="number"
                min="0"
                placeholder="До"
                v-model="maxPrice"
                @change="updateInput"
                @keydown.enter="$event.target.blur()"
              >
            </div>
          </div>
        </form>
      </aside>
    </div>
    <div
      v-if="!isSortingHidden"
      @click="hideSortingMenu"
      class="background-dark overflow-auto absolute z-10 inset-y-0 inset-x-0"
    ></div>
    <div
      v-if="isMobile & !isSortingHidden"
      class="sorting-mobile-menu overflow-auto flex flex-col justify-center items-center gap-3 rounded-t-2xl absolute bottom-0 inset-x-0 z-20"
    >
      <p class="text-[28px]">Показать сначала</p>
      <div class="sorting-mobile-menu__container rounded-lg flex flex-col justify-around px-5">
        <div v-for="sorting in sortings" :key="sorting" class="sorting-radio flex flex-row justify-start gap-3">
          <input
            type="radio"
            :id="sorting.name"
            :value="sorting.name"
            @change="getProducts(sorting.name)"
            v-model="sortedBy"
          >
          <label :for="sorting.name" class="text-[25px]">{{ sorting.title }}</label>
        </div>
      </div>
    </div>
    <div
      v-if="isMobile & !isFiltersHidden"
      class="filters-mobile-menu overflow-auto flex flex-col absolute inset-y-0 inset-x-0 z-20"
    >
      <form @submit.prevent="getProducts(this.sortedBy)" class="flex flex-col justify-center p-5 pb-24">
        <div class="filters-mobile-menu__header flex flex-row justify-between">
          <h3 class="font-bold">Фильтры</h3>
          <button @click="hideFiltersMenu" class="cancel">Отмена</button>
        </div>
        <div class="filters__categories mb-6">
          <div class="flex flex-row justify-between items-center mb-3">
            <h3 class="text-[24px] font-bold">Категории</h3>
            <ClearButton @clear="clearCategories"></ClearButton>
          </div>
          <div class="flex flex-col items-start gap-3">
            <CategoryCheckbox
              v-for="category in categories"
              :key="category"
              :categoryID="category.id"
              :categoryName="category.name"
              :subcategories="category.subcategories"
              @setCheck="toggleCategory"
              @setUnCheck="toggleCategory"
            ></CategoryCheckbox>
          </div>
        </div>
        <div class="filters__price">
          <div class="flex flex-row justify-between items-center mb-3">
            <h4 class="text-[24px] font-bold text-left">Цена</h4>
            <ClearButton @clear="clearPrices"></ClearButton>
          </div>
          <div class="price-inputs flex flex-row gap-2">
            <input
              class="rounded-lg text-center"
              type="number"
              min="0"
              placeholder="От"
              v-model="minPrice"
              @change="updateInput"
              @keydown.enter="$event.target.blur()"
            >
            <input
              class="rounded-lg text-center"
              type="number"
              min="0"
              placeholder="До"
              v-model="maxPrice"
              @change="updateInput"
              @keydown.enter="$event.target.blur()"
            >
          </div>
        </div>
        <button class="font-bold mt-6" type="submit">Применить</button>
      </form>
    </div>
  </section>
</template>


<script>
import axiosInstance from '@/const.js'
import ProductCardCatalog from '@/components/ProductCardCatalog'
import TitleItem from '@/components/TitleItem'
import LoadingMark from '@/components/UI/LoadingMark'
import ClearButton from '@/components/UI/ClearButton'
import CategoryCheckbox from '@/components/UI/CategoryCheckbox'

export default {
  components: {
    ProductCardCatalog,
    TitleItem,
    LoadingMark,
    ClearButton,
    CategoryCheckbox,
  },
  data() {
    return {
      products: [],
      productsRows: [],
      isProductsLoading: false,
      isCategoriesLoading: false,
      rowLength: 4,
      categories: [],
      minPrice: null,
      maxPrice: null,
      noProducts: false,
      searchText: null,
      isPriceUpdate: false,
      sortedBy: 'alphabet',
      isMobile: false,
      isSortingHidden: true,
      isFiltersHidden: true,
      sortings: [
        {
          name: 'alphabet',
          title: 'по алфавиту',
        },
        {
          name: 'cheaper',
          title: 'подешевле',
        },
        {
          name: 'expensive',
          title: 'подороже',
        },
        {
          name: 'new',
          title: 'по новизне',
        },
      ],
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.searchText = to.params.searchText;
    this.getProducts(this.sortedBy);
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.searchText = to.params.searchText;
    for (let category of this.categories) {
      this.$store.state.selectedCategories.add(String(category.id));
    }
    this.getProducts(this.sortedBy);
    next();
  },
  methods: {
    async getProducts(sorted) {
      try {
        this.products = [];
        this.noProducts = false;
        this.isSortingHidden = true;
        this.isFiltersHidden = true;
        document.body.style.overflowY = 'auto';
        this.sortedBy = sorted;
        this.isProductsLoading = true;
        const response = await axiosInstance.get(
          `catalog/products-by-categories/?sortedBy=${sorted}`,
          {
            params: {
              categories: Array.from(this.$store.state.selectedCategories),
              searchText: this.searchText,
              minPrice: this.minPrice,
              maxPrice: this.maxPrice,
            }
          }
        )
        if (response.status !== 200) {
          throw new Error(`Status ${response.status}`);
        }
        this.products = response.data;
        if (this.products.length === 0) {
          this.noProducts = true;
          return;
        }
        this.productsRows = [];
        if (!this.isPriceUpdate) {
          this.minPrice = this.maxPrice = Number(this.products[0].price);
        }
        let currentRow = [];
        for (let product of this.products) {
          if (currentRow.length < this.rowLength) {
            currentRow.push(product);
          } else {
            this.productsRows.push(currentRow);
            currentRow = [product];
          }
          if (!this.isPriceUpdate) {
            const price = Number(product.price);
            if (price < this.minPrice) {
              this.minPrice = price;
            }
            if (price > this.maxPrice) {
              this.maxPrice = price;
            }
          }
        }
        this.productsRows.push(currentRow);
      } catch (e) {
        alert(e);
      } finally {
        this.isProductsLoading = false;
      }
    },
    async getCategories() {
      try {
        this.isCategoriesLoading = true;
        const response = await axiosInstance.get('catalog/categories/');
        if (response.status !== 200) {
          throw new Error(`Status ${response.status}`);
        }
        this.categories = response.data;
        this.setInitCategory();
        this.getProducts(this.sortedBy);
      } catch (e) {
        alert(e);
      } finally {
        this.isCategoriesLoading = false;
        if (this.$route.name == 'catalog-by-search') {
          for (let category of this.categories) {
            this.$store.state.selectedCategories.add(String(category.id));
          }
        }
      }
    },
    setTypeScreen() {
      if (window.innerWidth <= 900) {
        this.isMobile = true;
        this.rowLength = 2;
      }
    },
    clearPrices() {
      this.isPriceUpdate = false;
      this.minPrice = null;
      this.maxPrice = null;
    },
    clearCategories() {
      this.$store.state.selectedCategories.clear();
      if (this.$route.name == 'catalog-by-category') {
        this.$store.state.selectedCategories.add(this.$route.params.id);
      }
      this.clearPrices();
      this.getProducts(this.sortedBy);
    },
    updateInput() {
      this.isPriceUpdate = true;
      if (!this.isMobile) {
        this.getProducts(this.sortedBy);
      }
    },
    showSortingMenu() {
      document.body.style.overflow = 'hidden';
      this.isSortingHidden = false;
    },
    showFiltersMenu() {
      document.body.style.overflow = 'hidden';
      this.isFiltersHidden = false;
    },
    hideSortingMenu() {
      document.body.style.overflow = 'auto';
      this.isSortingHidden = true;
    },
    hideFiltersMenu() {
      document.body.style.overflow = 'auto';
      this.isFiltersHidden = true;
    },
    toggleCategory() {
      this.clearPrices();
      if (!this.isMobile) {
        this.getProducts(this.sortedBy);
      }
    },
    setInitCategory() {
      for (let category of this.categories) {
        if (category.id == this.$route.params.id) {
          this.$store.state.selectedCategories.add(String(category.id));
          this.setSubcats(category.subcategories);
          return;
        }
      }
    },
    setSubcats(subcats) {
      for (let category of subcats) {
        this.$store.state.selectedCategories.add(String(category.id));
        this.setSubcats(category.subcategories);
      }
    }
  },
  mounted() {
    if (this.$route.name === 'catalog-by-search') {
      this.searchText = this.$route.params.searchText;
    }
    else if (this.$route.name == 'catalog-by-category') {
      this.$store.state.selectedCategories.clear();
    }
    this.setTypeScreen();
    this.getCategories();
  }
}
</script>

<style lang="scss">
@import '@/assets/variables.scss';

.filters {
  width: 20%;
}

.sort {
  button {
    color: #A4A19F;
    font-size: 25px;
  }

  button.active {
    color: black;
    font-size: 25px;
  }
}

.sort-mobile {
  width: 135px;
  height: 32px;
  background-color: $beige;
}

.sorting-mobile-menu {
  background-color: white;
  width: 100vw;
  height: 30vh;
}

.sorting-mobile-menu__container {
  background-color: #FFF4EF;
  width: 90%;
  height: 70%;
}

.filters-mobile-menu {
  background-color: white;
  width: 100vw;
  height: 100vh;

  form {
    background-color: white;

    button[type=submit] {
      color: white;
      font-size: 29px;
      background-color: $orange-pink;
      border-radius: 27px;
      height: 57px;
      width: 100%;
    }
  }
}

.filters-mobile-menu__header {
  font-size: 28px;

  &::before {
    content: " ";
  }
}

.sorting-radio {
  label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 0;
    line-height: 18px;
    user-select: none;
  }

  input[type=radio] {
    display: none;
  }

  label:before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    bottom: 1px;
    background: url("/src/assets/images/radio.png") 0 0 no-repeat;
  }

  input[type=radio]:checked + label:before {
    background: url("/src/assets/images/radio-checked.png") 0 0 no-repeat;
  }
}

input[type=number] {
  width: 141px;
  height: 56px;
  border: 3px solid #D9D9D9;
  font-size: 25px;
  color: black;

  &:focus {
    border: 3px solid #A8A8A8;
  }
}

.background-dark {
  background-color: black;
  opacity: 0.38;
  width: 100vw;
  height: 100vh;
}

.cancel {
  font-size: 17px;
  color: #FC7F45;
  right: 0;
}
</style>

<template>
  <div
    class="gap:transition duration-300 flex flex-col w-full"
    :style="{ 'gap': hidden ? '0rem' : '0.75rem', 'padding-left': `${10 + step * 10}px` }"
  >
    <div class="category-check">
      <input
        type="checkbox"
        :id="categoryID"
        :checked="isChecked(categoryID)"
        @change="change(categoryID)"
      >
      <label :for="categoryID" class="text-left text-[22px]">{{ categoryName }}</label>
      <button type="button" @click="isOpen">
        <img :class="{ 'reveal-arrow': true, 'expanded': !hidden }" src="@/assets/images/pngwing.com.png" width="15" height="15" alt="arrow">
      </button>
    </div>
    <div v-if="subcategories.length > 0"
      class="subcategories"
      :style="{ 'max-height': maxHeight, 'opacity': opacity, 'gap': hidden ? '0rem' : '0.75rem' }"
    >
      <CategoryCheckbox
        v-for="subcat in subcategories"
        :key="subcat"
        :categoryID="subcat.id"
        :categoryName="subcat.name"
        :subcategories="subcat.subcategories"
        :step="step + 1"
        @setUnCheck="onSetUnCheck"
        @setCheck="onSetCheck"
      ></CategoryCheckbox>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryCheckbox',
  props: {
    categoryID: Number,
    categoryName: String,
    step: {
      type: Number,
      default: 0,
    },
    subcategories: {
      type: Array,
      default() {
        return []
      },
    }
  },
  data() {
    return {
      hidden: true,
      maxHeight: '0px',
      opacity: '0',
      selectedSubcategories: [],
      subcategoriesIds: [],
    }
  },
  methods: {
    change(id) {
      if (!this.$store.state.selectedCategories.has(String(id))) {
        this.$store.state.selectedCategories.add(String(id));
        if (this.subcategoriesIds.length > 0) {
          for (let catId of this.subcategoriesIds) {
            this.$store.state.selectedCategories.add(String(catId));
          }
        }
        this.$emit('setCheck', this.categoryID);
      } else {
        this.$store.state.selectedCategories.delete(String(id));
        if (this.subcategoriesIds.length > 0) {
          for (let catId of this.subcategoriesIds) {
            this.$store.state.selectedCategories.delete(String(catId));
          }
        }
        this.$emit('setUnCheck', this.categoryID);
      }
    },
    isOpen() {
      this.hidden = !this.hidden;
      this.maxHeight = this.hidden ? '0px' : '2000px';
      this.opacity = this.hidden ? '0' : '1';
    },
    isChecked(id) {
      return this.$store.state.selectedCategories.has(String(id));
    },
    onSetCheck(id) {
      if (this.subcategoriesIds.includes(id)) {
        for (let subcatId of this.subcategoriesIds) {
          if (subcatId === id) continue;
          if (!this.$store.state.selectedCategories.has(String(subcatId))) {
            this.$emit('setCheck', this.categoryID);
            return;
          }
        }
        this.$store.state.selectedCategories.add(String(this.categoryID));
        this.$emit('setCheck', this.categoryID);
      }
    },
    onSetUnCheck(id) {
      if (this.subcategoriesIds.includes(id)) {
        this.$store.state.selectedCategories.delete(String(this.categoryID));
        this.$emit('setUnCheck', this.categoryID);
      }
    },
    setSubcatsIds(subcats) {
      for (let subcat of subcats) {
        this.subcategoriesIds.push(subcat.id);
        this.setSubcatsIds(subcat.subcategories);
      }
    },
  },
  mounted() {
    this.setSubcatsIds(this.subcategories);
  }
}
</script>

<style lang="scss">
.category-check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 0;
    line-height: 25px;
    user-select: none;
  }

  input[type=checkbox] {
    display: none;
  }

  label:before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 4px;
    bottom: 3px;
    left: 0;
    background: url("/src/assets/images/checkbox.png") 0 0 no-repeat;
  }

  input[type=checkbox]:checked + label:before {
    background: url("/src/assets/images/checkbox-checked.png") 0 0 no-repeat;
  }

  label:hover:before {
    background: url("/src/assets/images/checkbox-focus.png") 0 0 no-repeat;
  }
}

.subcategories {
  display: flex;
  flex-direction: column;
  transition: max-height .3s, opacity .3s;
}

.reveal-arrow {
  min-width: 15px;
  min-height: 15px;
  transform: rotate(0deg);
  transition: transform .2s;
}

.reveal-arrow.expanded {
  transform: rotate(90deg);
}
</style>

